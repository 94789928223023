import { createContext, useContext } from 'react';
import { CrmUser } from '../custom/types/crm/user';

export const CurrentUserContext = createContext<CrmUser | undefined>(undefined);

export function useCurrentUserContext() {
  const currentUser = useContext(CurrentUserContext);

  if (currentUser === undefined) {
    throw new Error('User is currently undefined');
  }

  return currentUser;
}
