import React from 'react';
import styled from '@emotion/styled';
import { TextField as MuiTextField, TextFieldVariants } from '@mui/material';
import { spacing } from '@mui/system';
import { FormikTouched } from 'formik';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type CustomCSSProperties = {
  [key: string]: string | number | React.CSSProperties;
};

export type FormikTextFieldMultilineProps<T> = {
  field: string;
  label: string;
  touched: FormikTouched<T>;
  values: T;
  errors: T;
  rows: number;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  variant?: TextFieldVariants;
  sx?: CustomCSSProperties;
};

export const FormikTextFieldMultiline = <T,>(props: FormikTextFieldMultilineProps<T>) => {
  const {
    field,
    label,
    touched,
    values,
    errors,
    rows,
    handleBlur,
    handleChange,
    disabled = false,
    variant = 'outlined',
    sx,
  } = props;
  return (
    <TextField
      name={field}
      label={label}
      // @ts-ignore
      value={values[field]}
      // @ts-ignore
      error={Boolean(touched[field] && errors[field])}
      fullWidth
      // @ts-ignore
      helperText={touched[field] && errors[field]}
      onBlur={handleBlur}
      onChange={handleChange}
      variant={variant}
      my={2}
      rows={rows}
      multiline
      size="small"
      disabled={disabled}
      sx={sx}
    />
  );
};
