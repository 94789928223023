import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, MentionData } from '@draft-js-plugins/mention';
import { Box, debounce, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { AuthContext } from '../../../../contexts/JWTContext';
import { AvailableTriggerCharactersTypes, ExtendedMentionInputProps } from '../../../types/crm/props/mentionProps';
import { GET_LEADS_MENTION, GET_PROJECTS_MENTION, GET_USERS_MENTION } from '../../../../common/graphQL';
import { CrmUserMentions } from '../../../types/crm/user';
import { CrmLeadsPaged } from '../../../types/crm/lead';
import { AllProjectsType } from '../../../types/project';
import { getLabelStyle } from './styles/formikMentionInputStyle';
import CustomSuggestionList from './CustomSuggestionList';
import { FieldProps } from 'formik';
import { CustomMentionOutput } from './CustomMentionOutput';
import { fetchMentionData } from './utils/fetchMentionData';
import { mentionInputValueFormatter } from './utils/mentionInputValueFormatter';
import '@draft-js-plugins/mention/lib/plugin.css';

interface StringKeyedObject {
  [key: string]: string | Object | Array<Object>;
}

const mentionsTheme = {
  mentionSuggestions: 'mentionSuggestions',
  mentionSuggestionsEntryContainer: 'mentionSuggestionsEntryContainer',
  mentionSuggestionsEntryContainerLeft: 'mentionSuggestionsEntryContainerLeft',
  mentionSuggestionsEntryContainerRight: 'mentionSuggestionsEntryContainerRight',
  mentionSuggestionsEntry: 'mentionSuggestionsEntry',
  mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
  mentionSuggestionsEntryText: 'mentionSuggestionsEntryText',
  mentionSuggestionsEntryTitle: 'mentionSuggestionsEntryTitle',
  mentionSuggestionsEntryAvatar: 'mentionSuggestionsEntryAvatar',
};

const DraftJSMentionInput: React.FC<ExtendedMentionInputProps<FieldProps & StringKeyedObject>> = ({
  label,
  field,
  setFieldValue,
  touched,
  errors,
  form,
}: ExtendedMentionInputProps<FieldProps & StringKeyedObject>) => {
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user } = useContext(AuthContext) || {};
  const userID = user && user['custom:nucleus-id'];
  const [prevTrigger, setPrevTrigger] = useState<AvailableTriggerCharactersTypes>(AvailableTriggerCharactersTypes.At);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [shouldValidateField, setShouldValidateField] = useState(false);

  /** This useEffects triggers when form is reset */
  /** Reset the field value */
  useEffect(() => {
    if (!touched[field.name] && editorState.getCurrentContent().hasText()) {
      setEditorState(EditorState.push(editorState, ContentState.createFromText(''), 'insert-characters'));
      setIsFocused(false);
      setIsError(false);
      setShouldValidateField(false);
    }
  }, [field, editorState, touched]);

  /** Draft JS Mention Configuration */
  const { MentionSuggestions: MentionSuggestionsCustom, plugins: pluginsCustom } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionTrigger: ['@'],
      mentionPrefix: trigger => trigger,
      theme: mentionsTheme,
      supportWhitespace: true,
      mentionComponent(mentionProps) {
        return <CustomMentionOutput mentionProps={mentionProps} />;
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  /** Queries */
  const [getUsers] = useLazyQuery<CrmUserMentions>(GET_USERS_MENTION);
  const [getLeads] = useLazyQuery<CrmLeadsPaged>(GET_LEADS_MENTION, {
    variables: {
      pagination: {
        limit: 5,
      },
      filter: {
        assignedBy: userID,
      },
    },
  });
  const [getProjects] = useLazyQuery<AllProjectsType>(GET_PROJECTS_MENTION);
  const queries = {
    users: {
      getUsers,
      defaultInputFilters: {
        userId: userID,
      },
    },
    leads: {
      getLeads,
      defaultInputFilters: {
        assignedBy: userID,
      },
    },
    projects: {
      getProjects,
    },
  };

  const fetchSuggestionList = debounce(
    async (triggerCharacter: AvailableTriggerCharactersTypes, searchQuery: string) => {
      fetchMentionData({ queries, triggerCharacter, searchQuery }).then(result => {
        setSuggestions(result);
      });
    },
    300,
  );

  const onChange = (editorStateValue: EditorState) => {
    const hasText = editorStateValue.getCurrentContent().hasText();
    setEditorState(editorStateValue);
    setFieldValue(field.name, mentionInputValueFormatter(editorStateValue));

    if (shouldValidateField) {
      setIsError(Boolean(!hasText && touched[field.name]));
    }
  };

  const onFocus = () => {
    setIsFocused(true);
    form.setFieldTouched(field.name, true).then(() => {});
  };

  const onBlur = () => {
    setIsError(!editorState.getCurrentContent().hasText());
    setIsFocused(false);
    setShouldValidateField(true);
  };

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    async (event: { trigger: string; value: string }) => {
      if (prevTrigger !== event.trigger) {
        setSuggestions([]);
      } else {
        setSuggestions(defaultSuggestionsFilter(event.value, suggestions));
      }
      setPrevTrigger(event.trigger as AvailableTriggerCharactersTypes);
      await fetchSuggestionList(event.trigger as AvailableTriggerCharactersTypes, event.value);
    },
    [fetchSuggestionList, prevTrigger, suggestions],
  );

  return (
    <Box>
      <Box
        sx={{
          border: isError ? '1px solid red' : isFocused ? '2px solid #1976d2' : '1px solid #ddd',
          boxSizing: 'border-box',
          cursor: 'text',
          padding: '16px',
          borderRadius: '2px',
          marginBottom: '0.5em',
          background: '#fefefe',
          position: 'relative',
          '& .public-DraftEditor-content': {
            minHeight: '23.563rem',
          },
        }}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={pluginsCustom}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <MentionSuggestionsCustom
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            setOpen(false);
          }}
          entryComponent={CustomSuggestionList}
        />
        <Typography sx={getLabelStyle(isFocused, isError, editorState.getCurrentContent().hasText())}>
          {label}
        </Typography>
      </Box>
      {isError && (
        <React.Fragment>
          <Typography color="error" sx={{ fontSize: '0.6964285714285714rem', margin: '4px 4px 0px 14px' }}>
            {errors[field.name] as React.ReactNode}
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
};

export default DraftJSMentionInput;
