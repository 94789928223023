import { createContext, useContext } from 'react';
import { Badge, Item, Title as SidebarNavListItemTitle } from '../../components/sidebar/SidebarNavListItem';
import { Title as SideNavSectionTitle } from '../../components/sidebar/SidebarNavSection';

type SidebarNavSectionComponentTheme = {
  Title?: typeof SideNavSectionTitle;
};

type SidebarNavListItemComponentTheme = {
  Item?: typeof Item;
  Title?: typeof SidebarNavListItemTitle;
  Badge?: typeof Badge;
};

type SidebarNavSectionTypes = {
  sidebarNavSectionComponentTheme?: SidebarNavSectionComponentTheme;
};

type SidebarNavListItemTypes = {
  sidebarNavListItemComponentTheme: SidebarNavListItemComponentTheme;
};

export type SidebarContextTypes = {
  sidebarNavSection?: SidebarNavSectionTypes;
  sidebarNavListItem?: SidebarNavListItemTypes;
};

export const SidebarContext = createContext<SidebarContextTypes | undefined>(undefined);

export function useSidebarContext() {
  const sidebar = useContext(SidebarContext);

  return sidebar;
}
