import * as React from 'react';
import { FieldProps, FormikTouched } from 'formik';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

type FormikCheckboxProps<T> = FieldProps & {
  label?: string;
  touched: FormikTouched<T>;
  errors: T;
  values: T;
  disabled?: boolean;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
};

const FormikCheckbox: React.FC<FormikCheckboxProps<FieldProps>> = ({
  field,
  label,
  disabled = false,
  handleChange,
}: FormikCheckboxProps<FieldProps>) => {
  return (
    <>
      <FormControlLabel
        control={<Checkbox disabled={disabled} defaultChecked={field.value} onChange={handleChange} />}
        label={label}
        name={field.name}
        slotProps={{
          typography: {},
        }}
      />
    </>
  );
};

export default FormikCheckbox;
