import React from 'react';
import { Field } from 'formik';
import { FormikTextFieldMultiline, FormikTextFieldMultilineProps } from './FormikTextFieldMultiline';
import { BaseMentionInputProps } from '../../types/crm/props/mentionProps';
import DraftJSMentionInput from './formikMentionInput/NucleusMentionInput';

type MentionInputPropsBase = {
  mention: boolean;
};

type MentionInputPropsType<T> =
  | (MentionInputPropsBase & ({ mention: true } & BaseMentionInputProps<T>))
  | ({ mention?: false } & FormikTextFieldMultilineProps<T>);

export const NucleusTextField = <T,>(props: MentionInputPropsType<T>) => {
  const { mention, ...rest } = props;

  if (mention) {
    return <Field component={DraftJSMentionInput} {...(rest as BaseMentionInputProps<T>)} />;
  } else {
    return <FormikTextFieldMultiline {...(rest as FormikTextFieldMultilineProps<T>)} />;
  }
};
