import React, { MouseEvent, ReactElement } from 'react';
import { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';
import LetterAvatar from '../../Avatar';
import { Box } from '@mui/material';
import { mentionAvatarBackgroundColorMap } from './styles/formikMentionInputStyle';
import { AvailableTriggerCharactersTypes } from '../../../types/crm/props/mentionProps';

export interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

export default function CustomSuggestionList(props: EntryComponentProps): ReactElement {
  const { mention, theme, ...parentProps } = props;

  const avatarBackgroundColor =
    mentionAvatarBackgroundColorMap[mention.triggerCharacter as AvailableTriggerCharactersTypes];

  return (
    <Box {...parentProps}>
      <Box className={theme?.mentionSuggestionsEntryContainer}>
        {/*avatar*/}
        <Box className={theme?.mentionSuggestionsEntryContainerLeft}>
          <LetterAvatar
            className={theme?.mentionSuggestionsEntryAvatar}
            sx={{
              backgroundColor: avatarBackgroundColor,
            }}
            name={mention.avatar ?? ''}
          />
        </Box>

        {/**/}
        <Box className={theme?.mentionSuggestionsEntryContainerRight}>
          <Box className={theme?.mentionSuggestionsEntryText}>{mention.title}</Box>

          <Box className={theme?.mentionSuggestionsEntryTitle}>{mention.subtitle}</Box>
        </Box>
      </Box>
    </Box>
  );
}
