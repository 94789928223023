import React, { useEffect, useRef, useState } from 'react';
import { Box, SxProps, Theme, Tooltip, Typography, TypographyProps } from '@mui/material';

interface EllipsisTextProps {
  content: string | EllipsisTextPropsReactNodeContent;
  additionalAttributes?: TypographyProps;
}

type EllipsisTextPropsReactNodeContent = {
  content: React.ReactNode;
  tooltipTitle: string;
};

const EllipsisText = (props: EllipsisTextProps) => {
  const { content: contentProp, additionalAttributes: additionalAttributesProp } = props;
  const tooltipTitle = typeof contentProp === 'string' ? contentProp : contentProp.tooltipTitle;
  const content = typeof contentProp === 'string' ? contentProp : contentProp.content;

  const defaultSxProps: SxProps<Theme> = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const additionalAttributes = {
    ...additionalAttributesProp,
    sx: {
      ...defaultSxProps,
      ...additionalAttributesProp?.sx,
    },
  };

  const ref = useRef<HTMLSpanElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    let getData: NodeJS.Timeout | undefined;

    const checkOverflow = () => {
      if (ref.current && ref.current.parentElement) {
        // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
        const clientWidth = ref.current.getBoundingClientRect().width;

        ref.current.style.overflow = 'visible';

        const contentWidth = ref.current.getBoundingClientRect().width;

        ref.current.style.overflow = 'hidden';

        setIsOverflown(clientWidth < contentWidth);
      }
    };

    const handleResize = () => {
      clearTimeout(getData);

      getData = setTimeout(() => {
        checkOverflow();
      }, 200);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ display: 'inline-grid' }}>
        <Tooltip title={tooltipTitle} disableHoverListener={!isOverflown}>
          <Typography component={'span'} ref={ref} {...additionalAttributes}>
            {content}
          </Typography>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default EllipsisText;
