import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChevronDown, ChevronUp } from 'react-feather';

interface SmsTaskFormProps {
  title?: string | React.ReactNode;
  subTitle?: string;
  body: React.ReactNode;
  collapsable?: boolean;
  expanded?: boolean;
  inheritBackground?: boolean;
  removeBoxShadow?: boolean;
  sx?: object;
  showIcon?: boolean;
  headerPadding?: number;
  showButtonText?: boolean;
  buttonColor?: 'black' | 'inherit';
  collapseBtnText?: {
    show: string;
    hide: string;
  };
}

const CollapsableCard = (props: SmsTaskFormProps) => {
  const {
    title,
    showIcon = true,
    expanded = true,
    collapsable = false,
    removeBoxShadow,
    inheritBackground,
    subTitle,
    body,
    sx,
    headerPadding,
    showButtonText = true,
    buttonColor = 'inherit',
    collapseBtnText = { show: 'Show Guide', hide: 'Hide Guide' },
  } = props;
  const [collapseBtnTextState, setCollapseBtnText] = useState<string>(
    expanded ? collapseBtnText.hide : collapseBtnText.show,
  );
  const [isOpen, setIsOpen] = useState<boolean>(expanded);

  const handleShowDetails = () => {
    setIsOpen(!isOpen);
    setCollapseBtnText(isOpen ? collapseBtnText.show : collapseBtnText.hide);
  };

  return (
    <Card
      sx={{
        backgroundColor: inheritBackground ? 'inherit' : undefined,
        boxShadow: removeBoxShadow ? 'none' : undefined,
        ...sx,
      }}
    >
      <CardHeader
        sx={{
          padding: headerPadding ? headerPadding : undefined,
          paddingBottom: headerPadding ? 0 : undefined,
        }}
        title={
          <Grid container xs={12} justifyContent={'space-between'}>
            <Grid item alignContent={'center'}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            {collapsable && (
              <Grid item>
                <Button size={'small'} onClick={handleShowDetails} sx={{ alignItems: 'left' }}>
                  {showIcon && <InfoOutlinedIcon sx={{ color: 'gray', mr: '.5rem' }} />}
                  {showButtonText && collapseBtnTextState}
                  {!isOpen ? (
                    <ChevronDown style={{ color: buttonColor }} />
                  ) : (
                    <ChevronUp style={{ color: buttonColor }} />
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        }
        subheader={
          <Typography variant={'subtitle1'} gutterBottom>
            {subTitle}
          </Typography>
        }
      />
      {isOpen && (
        <CardContent>
          <Grid>{body}</Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default CollapsableCard;
