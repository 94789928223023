import { CrmUser } from '../../../../../types/crm/user';
import { ActionTypes } from '../fetchMentionData';
import { MentionData } from '@draft-js-plugins/mention';
import { AvailableTriggerCharactersTypes } from '../../../../../types/crm/props/mentionProps';

export async function atQuery({ queries, searchQuery }: ActionTypes) {
  const result = await queries.users.getUsers({
    variables: {
      filter: {
        firstName: {
          contains: searchQuery,
        },
        lastName: {
          contains: searchQuery,
        },
      },
    },
  });

  return (
    result.data?.identity.usersPaged
      .filter((item: CrmUser) => item.id !== queries.users.defaultInputFilters.userId)
      .map((item_1: CrmUser) => {
        return {
          id: item_1.id,
          name: `${item_1.firstName} ${item_1.lastName}`,
          avatar: `${item_1.firstName} ${item_1.lastName}`,
          title: `${item_1.firstName} ${item_1.lastName}`,
          subtitle: item_1.email,
          triggerCharacter: AvailableTriggerCharactersTypes.At,
        } as MentionData;
      }) ?? []
  );
}
