import { Helmet } from 'react-helmet-async';
import { Box, Divider as MuiDivider, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import NucleusLoader from '../NucleusLoader';

interface NucleusPageProps {
  title: string;
  header: string;
  actions?: React.ReactNode;
  loading?: boolean;
  content: React.ReactNode;
}

const Divider = styled(MuiDivider)(spacing);

const NucleusPage = (props: NucleusPageProps) => {
  const { title, actions, loading, header, content } = props;

  return (
    <React.Fragment>
      <Helmet title={title} />
      <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Grid sx={{ marginBottom: 5 }} container justifyContent="space-between">
          <Grid item md={6}>
            <Typography variant="h3" gutterBottom display="inline">
              {header}
            </Typography>
          </Grid>
          <Grid item md={6} sx={{ textAlign: 'right' }}>
            {actions && actions}
          </Grid>
        </Grid>
        <Divider my={6} />
        <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          {loading ? <NucleusLoader /> : content}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default NucleusPage;
