import { SxProps, Theme } from '@mui/material';

export const mentionBackgroundColorMap = {
  '@': '#e8f8ff',
  '#': '#f5f4ca',
  '*': '#d8ffd0',
};

export const mentionColorMap = {
  '@': '#0073a5',
  '#': '#a68000',
  '*': '#159600',
};

export const mentionAvatarBackgroundColorMap = {
  '@': 'blue',
  '#': 'orange',
  '*': 'green',
};

export const getLabelStyle = (isFocused: boolean, isError: boolean, hasText: boolean): SxProps<Theme> => ({
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '1.4375em',
  padding: hasText ? '0 5px' : isFocused ? '0 5px' : '0',
  display: 'block',
  transformOrigin: 'left top 0px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(133% - 32px)',
  position: 'absolute',
  top: '0px',
  left: '0px',
  color: isError ? 'red' : isFocused ? '#376fd0' : 'gray',
  transform: hasText
    ? 'translate(10px, -10px) scale(0.8)'
    : isFocused
      ? 'translate(10px, -10px) scale(0.8)'
      : 'translate(14px, 9px) scale(1)',
  transition:
    'color 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1), max-width 200ms cubic-bezier(0, 0, 0.2, 1)',
  pointerEvents: 'none',
  zIndex: '1',
  backgroundColor: 'white',
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
});
