enum MicrophoneStatusEnum {
  NO_MICROPHONE = 'Microphone not detected. Please ensure a microphone is connected or enabled to make a call.',
  NOT_ALLOWED = 'Your microphone is currently blocked. Please unblock it to make a call.',
}

export function checkMicrophoneStatus() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(devices => {
      const microphones = devices.filter(device => device.kind === 'audioinput');
      if (microphones.length > 0) {
        return promptUserMicrophone();
      } else {
        return { status: false, message: MicrophoneStatusEnum.NO_MICROPHONE };
      }
    })
    .catch(() => {
      return { status: false, message: MicrophoneStatusEnum.NO_MICROPHONE };
    });
}

// Function to prompt user for microphone access
function promptUserMicrophone() {
  return navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(() => {
      return { status: true, message: 'Microphone available' };
    })
    .catch(() => {
      return { status: false, message: MicrophoneStatusEnum.NOT_ALLOWED };
    });
}
