import React from 'react';
import DraggableModal from './DraggableModal';
import { Alert, Grid, keyframes, Stack } from '@mui/material';
import { Call, CallEnd } from '@mui/icons-material';
import ContactProfileIcon, { ProfileIconSizeType } from '../../pages/contacts/profile/ContactProfileIcon';
import styled from '@emotion/styled';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../contexts/TwilioContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Minimize2 } from 'react-feather';

const pulseAnimation = keyframes`
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.3);
        opacity: 0;
    }
`;

const StyledAvatar = styled.div`
  height: 100px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border-radius: 50%;
    animation: ${pulseAnimation} 1s linear infinite;
  }

  &::after {
    animation-delay: 0.3s;
  }
`;

const MinimizedBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;
  margin: 0.2rem;

  &:hover {
    color: #5c5c5c;
    cursor: pointer;
  }
`;

const IncomingCall = () => {
  const {
    isAnswered,
    setIsMinimizedIncomingCall,
    acceptIncomingCall,
    rejectIncomingCall,
    callInstanceContext,
    incomingCallStatusContext,
    isMicEnabled,
  } = React.useContext(VoiceTokenContext) as VoiceTokenContextProps;

  return (
    <DraggableModal
      styles={{
        background: 'rgba(234,238,246,0.3)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(0, 122, 255, 0.36)',
        backdropFilter: 'blur(7px)',
      }}
    >
      <MinimizedBtn onClick={() => setIsMinimizedIncomingCall(true)}>
        <Minimize2 size={'1rem'} />
      </MinimizedBtn>
      <Grid container flexDirection={'column'} alignItems={'center'} textAlign={'center'} spacing={10} pt={2}>
        <Grid item>
          <h3>Incoming Call</h3>
        </Grid>
        <Grid item>
          <StyledAvatar>
            <ContactProfileIcon DisplayIcon={AccountCircleIcon} size={ProfileIconSizeType.XL} />
          </StyledAvatar>
        </Grid>
        <Grid item>
          <h2>{callInstanceContext?.parameters.From!}</h2>
        </Grid>
        <Grid item>{incomingCallStatusContext}</Grid>
        <Grid item my={5} container>
          <Stack direction="row" spacing={15} justifyContent="center" width="100%">
            <ContactProfileIcon
              DisplayIcon={CallEnd}
              iconTypeButton={{
                onClick: () => {
                  rejectIncomingCall();
                },
                color: 'rgb(255,3,3)',
              }}
              size={ProfileIconSizeType.MEDIUM}
            />
            {!isAnswered && (
              <ContactProfileIcon
                DisplayIcon={Call}
                iconTypeButton={{
                  onClick: () => {
                    acceptIncomingCall();
                  },
                  color: 'rgb(97, 189, 0, 1)',
                }}
                size={ProfileIconSizeType.MEDIUM}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {!isMicEnabled && (
        <Alert severity={'info'} sx={{ borderRadius: 0 }}>
          Microphone is disabled.
        </Alert>
      )}
    </DraggableModal>
  );
};

export default IncomingCall;
