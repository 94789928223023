import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { TooltipProps } from '@mui/material/Tooltip';

interface NucleusTooltipProps extends TooltipProps {
  title: string;
  children: React.ReactElement;
}

const NucleusTooltip: React.FC<NucleusTooltipProps> = (nucleusTooltipProps: NucleusTooltipProps) => {
  const { title, children, ...otherProps } = nucleusTooltipProps;
  return (
    <Tooltip title={title} {...otherProps}>
      {children}
    </Tooltip>
  );
};

export default NucleusTooltip;
