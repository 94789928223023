import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../contexts/TwilioContext';

type AlertState = {
  micStatus: 'denied' | 'granted' | 'prompt' | 'error' | undefined;
};

const MicrophonePermissionChecker = () => {
  const [error, setError] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState<AlertState>({ micStatus: undefined });
  const { setIsMicEnabled } = React.useContext(VoiceTokenContext) as VoiceTokenContextProps;

  const handleShowAlert = (props: AlertState) => {
    const { micStatus } = props;
    setShowAlert({ micStatus });
  };

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        const status = await navigator.permissions.query({ name: 'microphone' as PermissionName });
        status.onchange = () => {
          setIsMicEnabled(status.state === 'granted');
          handleShowAlert({ micStatus: status.state });
        };
        setIsMicEnabled(status.state === 'granted');
        switch (status.state) {
          case 'denied':
          case 'prompt':
            handleShowAlert({
              micStatus: 'denied',
            });
            break;
          case 'granted':
            handleShowAlert({
              micStatus: 'granted',
            });
            break;
          default:
            handleShowAlert({
              micStatus: undefined,
            });
            break;
        }
      } catch (err) {
        setError('Error checking microphone permission');
      }
    };

    checkMicrophonePermission();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // Auto-close alert after 10 seconds when micStatus is granted
  useEffect(() => {
    if (showAlert.micStatus === 'granted') {
      const timer = setTimeout(() => {
        handleShowAlert({ micStatus: undefined });
      }, 10000);

      return () => clearTimeout(timer); // Cleanup timeout when component unmounts or micStatus changes
    }
    return;
  }, [showAlert.micStatus]);

  return (
    <div>
      {showAlert.micStatus === 'denied' && (
        <>
          <Alert
            variant="filled"
            severity="warning"
            sx={{ mb: '1rem', borderRadius: 0 }}
            onClose={() => {
              handleShowAlert({
                micStatus: undefined,
              });
            }}
          >
            <AlertTitle>Warning</AlertTitle>
            Microphone access is disabled. Please enable it in your browser settings to ensure uninterrupted use of the
            Incoming and Outgoing Call features.
          </Alert>
        </>
      )}
      {error && (
        <Alert
          variant="filled"
          severity="error"
          sx={{ mb: '1rem', borderRadius: 0 }}
          onClose={() => {
            handleShowAlert({
              micStatus: undefined,
            });
          }}
        >
          {error}
        </Alert>
      )}
    </div>
  );
};

export default MicrophonePermissionChecker;
