import React from 'react';
import { FieldProps } from 'formik';
import { MuiTelInput, MuiTelInputInfo } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js/types';

// Define the types for the custom props
interface FormikMuiTelInputProps extends FieldProps {
  onlyCountries?: CountryCode[]; // Optional prop to restrict the allowed countries
}

const FormikPhoneNumberInput: React.FC<FormikMuiTelInputProps> = ({
  field, // { name, value, onChange, onBlur } from Formik
  form: { touched, errors, setFieldValue }, // Formik's form object to get errors and touched states
  onlyCountries = [], // Default empty array for allowed countries
  ...props // Additional props for the MuiTelInput
}) => {
  const { name, value } = field;

  // Handle the change event and integrate validation
  const handleChange = (_value: string, info: MuiTelInputInfo) => {
    setFieldValue(name, info.numberValue); // Update the field value in Formik
  };

  return (
    <MuiTelInput
      {...field} // Spread Formik field props like value and onBlur
      {...props} // Spread additional props passed to the component
      value={value ?? ''}
      defaultCountry={'US'} // Set the default country
      size={'small'}
      fullWidth
      onChange={handleChange}
      error={touched[name] && Boolean(errors[name])} // Show error if touched and invalid
      helperText={touched[name] && errors[name] ? String(errors[name]) : ''} // Display error message
      onlyCountries={onlyCountries} // Restrict countries if provided
    />
  );
};

export default FormikPhoneNumberInput;
