import { CrmLead } from '../../../../../types/crm/lead';
import { ActionTypes } from '../fetchMentionData';
import { MentionData } from '@draft-js-plugins/mention';
import { AvailableTriggerCharactersTypes } from '../../../../../types/crm/props/mentionProps';

export async function hashQuery({ queries, searchQuery }: ActionTypes) {
  const result = await queries.leads.getLeads({
    variables: {
      filter: {
        assignedBy: queries.leads.defaultInputFilters.assignedBy,
        email: searchQuery,
      },
    },
  });

  return (
    result.data?.crm.leadsPaged.leads.map(
      (item: CrmLead) =>
        ({
          id: item.id,
          name: item.name,
          avatar: item.name,
          title: item.name,
          subtitle: item.email,
          triggerCharacter: AvailableTriggerCharactersTypes.Hash,
        }) as MentionData,
    ) ?? []
  );
}
