import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { blue, orange, red } from '@mui/material/colors';
import { SxProps, Theme } from '@mui/material';

type LetterAvatarTheme = {
  backgroundColor?: String;
  padding?: number | string;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
  //you can add additional styles here to customize it
};

export type LetterAvatarProps = {
  name: string;
  sx?: SxProps<Theme>;
  className?: string;
};

export type ProfileAvatarProps = {
  src: string;
  alt?: string;
};

export type LogoAvatarProps = {
  src: string;
  alt?: string;
};

export function ProfileAvatar(props: ProfileAvatarProps) {
  const { src, alt } = props;
  return (
    <Avatar
      sx={{
        height: 32,
        width: 32,
      }}
      alt={alt ?? 'image'}
      src={src}
    />
  );
}

export function LogoAvatar(props: LogoAvatarProps) {
  const { src, alt } = props;
  return (
    <Avatar
      sx={{
        borderRadius: 0,
        height: 30,
        width: 30,
      }}
      alt={alt ?? 'image'}
      src={src}
    />
  );
}

function stringAvatar(name: string | null, sx: LetterAvatarTheme) {
  let initials = 'N/A';

  if (name && name.trim().length > 0) {
    // Split the name by spaces, filter out empty parts, and take the first two valid parts
    const nameParts = name
      .trim()
      .split(' ')
      .filter(part => part !== '');

    if (nameParts.length >= 2) {
      // If there are at least two parts, use the first letter of the first and second part
      initials = `${nameParts[0][0].toUpperCase()}${nameParts[1][0].toUpperCase()}`;
    } else if (nameParts.length === 1) {
      // If there's only one part, use the first letter of that part
      initials = `${nameParts[0][0].toUpperCase()}`;
    }
  }

  return {
    sx: {
      backgroundColor:
        sx.backgroundColor === 'orange'
          ? orange[500]
          : sx.backgroundColor === 'blue'
            ? blue[500]
            : sx.backgroundColor === 'red'
              ? red[500]
              : `${sx.backgroundColor}`,
      height: sx.height,
      width: sx.width,
      padding: sx.padding,
      fontSize: sx.fontSize,
    },
    children: initials,
  };
}

function LetterAvatar(props: LetterAvatarProps) {
  const { name, sx: letterAvatarThemeProps } = props;

  const defaultLetterAvatarTheme: SxProps<Theme> = {
    backgroundColor: 'blue',
    p: 0,
    width: '32px',
    height: '32px',
    fontSize: '13px',
  };

  const sx = {
    ...defaultLetterAvatarTheme,
    ...letterAvatarThemeProps,
  };

  const avatarProps = stringAvatar(name, sx);
  return <Avatar {...avatarProps} />;
}

export default LetterAvatar;
