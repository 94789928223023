import React, { useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, InputAdornment, TextField as MuiTextField } from '@mui/material';
import { spacing } from '@mui/system';
import { FormikTouched } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
type TextFieldThemeType = {
  width: string;
  mr: number;
};

type FormikPasswordFieldProps<T> = {
  field: string; //
  label: string; //
  touched: FormikTouched<T>; //
  values: T; //
  errors: T; //
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>; //
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>; //
  type?: string; //
  theme?: TextFieldThemeType;
  size?: 'small' | 'medium';
};

/**
 * A custom form field component for use with Formik.
 *
 * @template T - The type of the form field value.
 * @param {FormikTextFieldProps<T>} props - The props for the FormikTextField component.
 * @returns {JSX.Element} - The rendered FormikTextField component.
 */
export const FormikPasswordField = <T,>(props: FormikPasswordFieldProps<T>): JSX.Element => {
  const {
    field,
    label,
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
    theme = {
      width: '100%',
      mr: 0,
    },
    size = 'medium',
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      sx={theme}
      type={showPassword ? 'text' : 'password'}
      name={field}
      label={label}
      value={values[field as keyof T]}
      error={Boolean(touched[field as keyof T] && errors[field as keyof T])}
      fullWidth
      helperText={touched[field as keyof T] && (errors[field as keyof T] as React.ReactNode)}
      onBlur={handleBlur}
      onChange={handleChange}
      variant="outlined"
      my={2}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handlePasswordVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      size={size}
    />
  );
};
