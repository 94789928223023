import { convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import { AvailableTriggerCharactersTypes } from '../../../../types/crm/props/mentionProps';

// Define a mapping from entity types to trigger characters
const entityTypeToTrigger: Record<string, string> = {
  mention: AvailableTriggerCharactersTypes.At,
  '#mention': AvailableTriggerCharactersTypes.Hash,
  '*mention': AvailableTriggerCharactersTypes.Asterisk,
};

const markupMentionsInBlock = (rawContent: RawDraftContentState) => {
  const { blocks, entityMap } = rawContent;
  let formattedValueWithMarkup = '';

  blocks.forEach(block => {
    let blockText = block.text;

    // Iterate over entityRanges in reverse order to avoid messing up indexes after replacement
    block.entityRanges.reverse().forEach(range => {
      const entityKey = range.key;
      const entity = entityMap[entityKey];

      if (entity) {
        // Determine the trigger character based on entity type
        const triggerCharacter = entityTypeToTrigger[entity.type];

        if (triggerCharacter) {
          const { id, name } = entity.data.mention;

          // Format the replacement text with brackets
          const replacementText = `[${triggerCharacter}:${id}:${name}]`;

          // Replace the mention name with the formatted ID text
          blockText = blockText.slice(0, range.offset) + replacementText + blockText.slice(range.offset + range.length);
        }
      }
    });

    // Remove extra spaces and trim both ends
    blockText = blockText.replace(/\s+/g, ' ').trim();

    // Accumulate the updated text from all blocks
    formattedValueWithMarkup += `${blockText} `; // Add a space between blocks (optional)
  });

  return formattedValueWithMarkup;
};

export const mentionInputValueFormatter = (editorState: EditorState): string => {
  const rawContent = convertToRaw(editorState.getCurrentContent());
  const formattedMentionInputValue = markupMentionsInBlock(rawContent);
  return formattedMentionInputValue.trim();
};
