import React from 'react';
import { Alert, AlertColor } from '@mui/material';

type NucleusAlertProps = {
  severity: AlertColor | undefined;
  message: string;
  onClose?: () => void;
};

const NucleusAlert: React.FC<NucleusAlertProps & React.HTMLAttributes<HTMLDivElement>> = (
  props: NucleusAlertProps & React.HTMLAttributes<HTMLDivElement>,
) => (
  <Alert
    severity={props.severity}
    onClose={props.onClose}
    sx={{
      mt: 2,
      mb: 1,
      '& .MuiAlert-message': {
        width: '100%',
        textAlign: 'center',
      },
      '& .MuiAlert-icon': {
        alignItems: 'center',
      },
      '& .MuiAlert-action': {
        alignItems: 'center',
      },
      ...props.style, // This is the style prop from the parent component
    }}
  >
    {props.message}
  </Alert>
);

export default NucleusAlert;
