import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FieldProps, FormikTouched } from 'formik';
import { StandaloneSearchBox } from '@react-google-maps/api';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, InputAdornment } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

type FormikAutoCompleteChipProps<T extends object> = FieldProps & {
  options: Array<{ label: string; id: number }>;
  label?: string;
  values: T;
  errors: T;
  touched: FormikTouched<T>;
  defaultValue: string;
  setFieldValue?: (value: string | Object | Array<Object>) => void;
  isOptionEqualToValue?: boolean;
  fieldProp: string;
  onSearchBoxLoad: (ref: google.maps.places.SearchBox) => void;
  handlePlaceChanged: () => void;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  innerRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
};

interface StringKeyedObject {
  [key: string]: string | Object | Array<Object>;
}

const FormikGoogleMapsSearchBox: React.FC<FormikAutoCompleteChipProps<FieldProps & StringKeyedObject>> = ({
  options,
  values,
  label,
  form,
  field,
  fieldProp,
  setFieldValue,
  errors,
  touched,
  isOptionEqualToValue = false,
  onSearchBoxLoad,
  handlePlaceChanged,
  handleBlur,
  handleChange,
  defaultValue,
  innerRef,
  disabled,
  textFieldProps,
  ...otherProps
}) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%' }}>
      <StandaloneSearchBox {...otherProps} {...field} onLoad={onSearchBoxLoad} onPlacesChanged={handlePlaceChanged}>
        <TextField
          name={field.name}
          label={label}
          defaultValue={defaultValue}
          value={values[field.name]}
          error={Boolean(touched[field.name] && errors[field.name])}
          fullWidth
          helperText={touched[field.name] && (errors[field.name] as React.ReactNode)}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
          size="small"
          inputRef={innerRef}
          disabled={disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                  <Button onClick={handlePlaceChanged}>
                    <SearchOutlinedIcon sx={{ color: '#fff' }} />
                  </Button>
                </Avatar>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: 'white',
            paddingY: '0.8rem',
            border: 'none',
            '& fieldset': { border: 'none' },
            borderRadius: '15px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
          }}
          {...textFieldProps}
        />
      </StandaloneSearchBox>
    </Box>
  );
};

export default FormikGoogleMapsSearchBox;
