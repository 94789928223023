import React, { useState, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@mui/material';

interface Position {
  x: number;
  y: number;
}

interface DraggableModalProps {
  position?: Position;
  border?: string;
  background?: string;
  styles?: React.CSSProperties;
  children: React.ReactNode;
}

// Slide-in animation
const slideIn = keyframes`
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

const AnimatedContainer = styled.div`
  animation: ${slideIn} 0.2s ease-out;
`;

const DraggableModal = (props: DraggableModalProps) => {
  const { position, border, background, styles, children } = props;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [positionState, setPositionState] = useState<Position>(position || { x: window.innerWidth - 320 - 80, y: 80 });
  const [offset, setOffset] = useState<Position>(position || { x: 0, y: 0 });

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - positionState.x,
      y: e.clientY - positionState.y,
    });
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      setPositionState({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <AnimatedContainer
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{
        ...styles,
        position: 'absolute',
        left: positionState.x,
        top: positionState.y,
        cursor: isDragging ? 'grabbing' : 'grab',
        backgroundColor: background ? background : 'none',
        border: border ? border : 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '0.5rem',
        zIndex: 10000,
        width: '20rem',
        overflow: 'hidden',
      }}
    >
      {children}
    </AnimatedContainer>
  );
};

export default DraggableModal;
