import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import styled from '@emotion/styled';

interface LoaderProps {
  type?: 'circular' | 'progress';
  value?: number;
  color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'warning';
  sx?: SxProps<Theme>;
  variant?: CircularProgressProps['variant'] | LinearProgressProps['variant'];
  size?: number | string; // Size for CircularProgress
}

const Center = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

class NucleusLoader extends React.Component<LoaderProps> {
  static defaultProps = {
    type: 'progress',
    color: 'primary',
    variant: 'indeterminate',
  };

  render() {
    const { type, value, color, sx, variant, size } = this.props;

    if (type === 'circular') {
      return (
        <Center>
          <CircularProgress
            color={color}
            sx={sx}
            variant={variant as CircularProgressProps['variant']}
            value={value}
            size={size}
          />
        </Center>
      );
    }

    // Default to linear progress
    return (
      <LinearProgress
        color={color}
        sx={sx}
        variant={value !== undefined ? 'determinate' : (variant as LinearProgressProps['variant'])}
        value={value}
      />
    );
  }
}

export default NucleusLoader;
