import React, { createContext, ReactNode, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { fetchUserAttributes, resetPassword, signIn } from 'aws-amplify/auth';
import { AuthUser, CognitoContextType } from '../types/auth';
import '@aws-amplify/ui-react/styles/reset.layer.css'; // global CSS reset
import { Brand } from '../pages/auth/SignIn';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import NucleusLoader from '../custom/NucleusLoader';
import { Amplify } from 'aws-amplify';
import { Env, fetchEnv } from '../custom/utils/getEnv';

const env: Env = await fetchEnv();

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          redirectSignIn: env.redirects,
          redirectSignOut: env.redirects,
          domain: env.domain,
          providers: env.providers,
          scopes: env.scopes,
          responseType: 'token',
        },
        username: true,
      },
      userPoolId: env.userPoolId,
      userPoolClientId: env.clientId,
    },
  },
});
interface AuthProviderProps extends WithAuthenticatorProps {
  children: ReactNode;
}

const AuthContext = createContext<CognitoContextType | null>(null);

// @ts-ignore
function AuthProvider(props: AuthProviderProps) {
  const { children, signOut } = props;
  const [user, setUser] = useState<AuthUser>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const authUser = await fetchUserAttributes();
        setUser(authUser);
        setIsAuthorized(true);
      } catch (error) {
        if (signOut) {
          signOut();
        }
      } finally {
        setIsInitialized(true);
      }
    };

    initializeUser();
  }, [signOut]);

  if (!isInitialized) {
    return <NucleusLoader />;
  }

  if (!isAuthorized) {
    // Optionally, return a message for unauthorized users
    return <NucleusLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        isInitialized,
        user,
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const AuthProviderWithAuthenticator = withAuthenticator(AuthProvider, {
  hideSignUp: true,
  initialState: 'signIn',
  components: {
    SignIn: {
      Header() {
        const [errorDescription, setErrorDescription] = useState<string | null>(null);

        useEffect(() => {
          const params = new URLSearchParams(window.location.hash.substring(1));
          const errorDesc = params.get('error_description');
          if (errorDesc) {
            setErrorDescription(decodeURIComponent(errorDesc));
          }
        }, []);

        return (
          <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Brand />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h5">Welcome to Nucleus</Typography>
                <Typography variant="subtitle1">Sign in to your account to continue</Typography>
              </Grid>
            </Grid>
            {errorDescription && (
              <Grid container sx={{ px: '2rem' }}>
                <Alert
                  severity="error"
                  onClose={() => {
                    setErrorDescription(null);
                  }}
                >
                  {errorDescription}
                </Alert>
              </Grid>
            )}
          </React.Fragment>
        );
      },
    },
    ForgotPassword: {
      Header() {
        return (
          <React.Fragment>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Brand />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h5">Reset Password</Typography>
              </Grid>
            </Grid>
            <Divider />
          </React.Fragment>
        );
      },
    },
  },
});

export { AuthContext, AuthProviderWithAuthenticator as AuthProvider };
