import React from 'react';
import styled from '@emotion/styled';
import { Grid, Typography, keyframes, useMediaQuery } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactProfileIcon, { ProfileIconSizeType } from '../../pages/contacts/profile/ContactProfileIcon';
import { Call, CallEnd } from '@mui/icons-material';
import { VoiceTokenContext, VoiceTokenContextProps } from '../../contexts/TwilioContext';
import { useTheme } from '@mui/system';

// Slide-in animation
const scale = keyframes`
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(100%);
        opacity: 1;
    }
`;

interface StyledButtonProps {
  backgroundColor: string;
  color: string;
  name?: string;
}

const IncomingCallMini = () => {
  const {
    setIsMinimizedIncomingCall,
    isAnswered,
    acceptIncomingCall,
    rejectIncomingCall,
    incomingCallStatusContext,
    callInstanceContext,
  } = React.useContext(VoiceTokenContext) as VoiceTokenContextProps;
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const StyledCard = styled.div`
    position: absolute;
    width: 300px;
    height: 58px;
    background: rgba(234, 238, 246, 0.3);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(23, 28, 35, 0.36);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(4.7px);
    border-radius: 10px;
    z-index: 10000;
    display: flex;
    top: 0.2rem; /* Adjust this value for vertical positioning */
    left: 50%;
    transform: ${isMdScreen ? 'translateX(-50%)' : null};
    animation: ${scale} 0.3s ease-in-out;
    overflow: hidden;
  `;

  const StyleButton = styled.div<StyledButtonProps>`
    name: ${props => props.name};
    border: none;
    cursor: pointer;
    width: 100%;
    color: ${props => props.color};
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${props => props.backgroundColor};
      color: #f0ecf7;
    }
  `;

  return (
    <StyledCard>
      <Grid
        container
        alignItems={'center'}
        flexWrap={'nowrap'}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(9, 103, 206, 0.14)',
            borderRadius: '10px 0 0 10px',
          },
        }}
        onClick={() => setIsMinimizedIncomingCall(false)}
      >
        <Grid item xs={3} sx={{ p: '.5rem' }}>
          <ContactProfileIcon DisplayIcon={AccountCircleIcon} size={ProfileIconSizeType.SMALL} />
        </Grid>
        <Grid item xs={9} sx={{ p: '.5rem' }}>
          <Typography variant={'body2'}>{callInstanceContext?.parameters.From!}</Typography>
          <Typography variant={'caption'}>{incomingCallStatusContext}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={!isAnswered ? 'row-reverse' : 'row'}
        xs={3}
        alignItems={'stretch'}
        sx={{ borderLeft: '1px solid #8888' }}
        justifyContent={'center'}
      >
        {!isAnswered && (
          <StyleButton
            backgroundColor={'rgb(97, 189, 0, 1)'}
            color={'rgb(97, 189, 0, 1)'}
            style={{
              textAlign: 'center',
              borderBottom: '1px solid #8888',
            }}
            onClick={() => acceptIncomingCall()}
          >
            <Call />
          </StyleButton>
        )}
        <StyleButton
          name={'cancel'}
          backgroundColor={'rgb(255,3,3)'}
          color={'rgb(255,3,3)'}
          style={{
            borderRadius: '0 0 10px 0',
            textAlign: 'center',
          }}
          onClick={() => rejectIncomingCall()}
        >
          <CallEnd />
        </StyleButton>
      </Grid>
    </StyledCard>
  );
};

export default IncomingCallMini;
