import { AvailableTriggerCharactersTypes, MentionQueryTypes } from '../../../../types/crm/props/mentionProps';
import { atQuery } from './fetchMentionDataQuery/atQuery';
import { hashQuery } from './fetchMentionDataQuery/hashQuery';
import { asteriskQuery } from './fetchMentionDataQuery/asteriskQuery';
import { MentionData } from '@draft-js-plugins/mention';

type FetchDataParameterTypes = {
  queries: MentionQueryTypes;
  triggerCharacter: AvailableTriggerCharactersTypes;
  searchQuery: string;
};

export type ActionTypes = {
  queries: MentionQueryTypes;
  searchQuery: string;
};

export async function fetchMentionData({
  queries,
  triggerCharacter,
  searchQuery,
}: FetchDataParameterTypes): Promise<MentionData[]> {
  let actionResult: MentionData[] = [];
  try {
    switch (triggerCharacter) {
      case AvailableTriggerCharactersTypes.At:
        actionResult = await atQuery({ queries, searchQuery });
        break;
      case AvailableTriggerCharactersTypes.Hash:
        actionResult = await hashQuery({ queries, searchQuery });
        break;
      case AvailableTriggerCharactersTypes.Asterisk:
        actionResult = await asteriskQuery({ queries, searchQuery });
        break;
      default:
        break;
    }

    return actionResult;
  } catch (error) {
    throw new Error(`Failed to fetch data`);
  }
}
