import React, { ReactNode } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show an alert with the error.
    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      // Display an alert with the error message
      return (
        <React.Fragment>
          <div style={{ backgroundColor: 'red', color: 'white', padding: '10px' }}>
            <strong>Error:</strong> {this.state.errorMessage}
          </div>
          {this.props.children}
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
