import React from 'react';
import styled from '@emotion/styled';
import { InputAdornment, TextField as MuiTextField, SxProps, Theme, Box } from '@mui/material';
import { spacing } from '@mui/system';
import { FormikErrors, FormikTouched } from 'formik';

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export type FormikTextFieldProps<T> = {
  field: string;
  label?: string | boolean;
  autoFocus?: boolean;
  adornment?: {
    icon?: React.ReactNode;
    text?: string;
    position: 'start' | 'end';
  };
  touched: FormikTouched<T>;
  values: T;
  errors: FormikErrors<T>;
  handleBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  disabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  defaultValue?: String;
  type?: string;
  sx?: SxProps<Theme>;
  minAmount?: number;
};

/**
 * A custom form field component for use with Formik.
 *
 * @template T - The type of the form field value.
 * @param {FormikTextFieldProps<T>} props - The props for the FormikTextField component.
 * @returns {JSX.Element} - The rendered FormikTextField component.
 */
export const FormikTextField = <T,>(props: FormikTextFieldProps<T>): JSX.Element => {
  const {
    field,
    label = false,
    autoFocus,
    adornment,
    touched,
    values,
    errors,
    handleBlur,
    handleChange,
    disabled = false,
    onKeyDown,
    defaultValue,
    type = 'text',
    sx: sxProps,
    minAmount = 0,
  } = props;

  const defaultTheme: SxProps<Theme> = {
    width: '100%',
    mr: 0,
  };

  const sx = {
    ...defaultTheme,
    ...sxProps,
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        sx={sx}
        type={type}
        name={field}
        label={label || undefined}
        defaultValue={defaultValue}
        value={values[field as keyof T]}
        error={Boolean(touched[field as keyof T] && errors[field as keyof T])}
        fullWidth
        onKeyDown={onKeyDown}
        helperText={touched[field as keyof T] && (errors[field as keyof T] as React.ReactNode)}
        onBlur={handleBlur}
        onChange={handleChange}
        variant="outlined"
        autoFocus={autoFocus}
        disabled={disabled}
        inputProps={{
          ...(type === 'number' && { min: minAmount }), // Add min input for number type
          ...(type === 'file' && { accept: 'image/*' }), // Handle file input case
        }}
        InputProps={{
          ...(adornment && {
            [adornment.position === 'start' ? 'startAdornment' : 'endAdornment']: (
              <InputAdornment position={adornment.position}>
                {/* Render icon if provided */}
                {adornment.icon && <span style={{ marginRight: adornment.text ? '0.5rem' : 0 }}>{adornment.icon}</span>}
                {/* Render text if provided */}
                {adornment.text && <span>{adornment.text}</span>}
              </InputAdornment>
            ),
          }),
        }}
        size="small"
      />
    </Box>
  );
};
