export const stringFormatter = (inputString: string) => {
  const words = inputString
    .toLowerCase()
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

  return words.join(' ');
};
