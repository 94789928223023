import { Box, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import LetterAvatar from '../../Avatar';
import {
  mentionAvatarBackgroundColorMap,
  mentionBackgroundColorMap,
  mentionColorMap,
} from './styles/formikMentionInputStyle';
import { AvailableTriggerCharactersTypes } from '../../../types/crm/props/mentionProps';
import React from 'react';
import styled from '@emotion/styled';
import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  },
}));

export type CustomMentionOutputProps = {
  mentionProps: SubMentionComponentProps;
};

export const CustomMentionOutput = (props: CustomMentionOutputProps) => {
  const triggerCharacter = props.mentionProps.mention.triggerCharacter;
  const avatarBackgroundColor = mentionAvatarBackgroundColorMap[triggerCharacter as AvailableTriggerCharactersTypes];
  return (
    <BootstrapTooltip
      title={
        <Box
          sx={{
            minWidth: '100px',
            color: 'black',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <LetterAvatar
              name={props.mentionProps.mention.avatar ?? ''}
              sx={{ width: '70px', height: '70px', fontSize: '20px', backgroundColor: avatarBackgroundColor }}
            />
            <Box sx={{ marginLeft: '5px', marginTop: '5px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Typography>{props.mentionProps.mention.title}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Typography sx={{ color: '#a7a7a7', fontSize: '0.813rem' }}>
                  {props.mentionProps.mention.subtitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    >
      <Typography
        component="span"
        className={props.mentionProps.className}
        sx={{
          background:
            mentionBackgroundColorMap[props.mentionProps.mention.triggerCharacter as AvailableTriggerCharactersTypes],
          color: mentionColorMap[props.mentionProps.mention.triggerCharacter as AvailableTriggerCharactersTypes],
          cursor: 'pointer',
          p: 1,
          borderRadius: 2,
          mb: 2,
        }}
      >
        {props.mentionProps.children}
      </Typography>
    </BootstrapTooltip>
  );
};
