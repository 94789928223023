import { createContext, useContext } from 'react';

type UserProfileContextType = {
  passwordAndSecurityTypes: PasswordAndSecurityTypes;
};

type PasswordAndSecurityTypes = {
  allowResetPassword: boolean;
};

export const UserProfileContext = createContext<UserProfileContextType | undefined>(undefined);

export function useUserProfileDetails() {
  const userProfile = useContext(UserProfileContext);

  if (userProfile === undefined) {
    throw new Error('User is currently undefined');
  }

  return userProfile;
}
