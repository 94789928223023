import { ProjectType } from '../../../../../types/project';
import { ActionTypes } from '../fetchMentionData';
import { MentionData } from '@draft-js-plugins/mention';
import { AvailableTriggerCharactersTypes } from '../../../../../types/crm/props/mentionProps';

export async function asteriskQuery({ queries }: ActionTypes) {
  const result = await queries.projects.getProjects();
  return (
    result.data?.projectManagement.projects.map(
      (item: ProjectType) =>
        ({
          id: item.id,
          name: `Project (${item.id})`,
          title: 'Project',
          subtitle: item.id,
          avatar: 'Project',
          triggerCharacter: AvailableTriggerCharactersTypes.Asterisk,
        }) as MentionData,
    ) ?? []
  );
}
